import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from '@emailjs/browser';

import Navbar from './navbar';
import Footer from './footer';

import us from './../img/analytics-7168479_640.png';
import dataP from './../img/dart-155726_640.png';
import creative from './../img/artist-1297400_640.png';
import trace from './../img/graph-6030184_640.png';
import optimize from './../img/tools-98391_640.png';
import clientp from './../img/headset-7727268_640.png';
import cross from './../img/social-media-6073859_640.png';
import innovation from './../img/light-311311_640.png';
import flexible from './../img/digital-nomad-6956070_640.png';
import fb from './../img/communication_15047435.png';
import x from './../img/twitter-alt-circle_12107562.png';
import ig from './../img/instagram_2111463.png';
import starter from './../img/business-6827797_640.png';
import growth from './../img/mountain-7011121_640.png';
import premium from './../img/success-7097008_640.png';

function ScrollToSection() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return null;
}

function Mainpage() {
    const form = useRef();

    const send = async (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_u0lucxo', 'template_q7uxy1l', form.current, {
                publicKey: 'eiBuna01VSFwEN924',
            })
            .then(
                () => {
                    alert("We have received your submission! We will reply to you within 1-2 business days.");
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    return (
        <>
            <Navbar />
            <ScrollToSection />

            <section id="home">
                <div>
                    <h1>Welcome</h1>
                    <h3>Engage. Influence. Succeed – Start Your Campaign Today!</h3>
                </div>
            </section>

            <section id="Aboutus">
                <h2>About Us</h2>
                <div>
                    <p>
                        <b>Welcome to ImpulseAd – where we turn clicks into connections, and campaigns into conversions...</b>
                        Founded in 2024, our mission is to elevate your brand's online presence, engaging your audience with compelling and effective advertising that captures attention and drives results.

                        At ImpulseAd, we’re more than just digital marketers. We’re a team of passionate creators, strategists, and innovators dedicated to helping brands thrive in an ever-evolving digital world. From startups finding their voice to established businesses seeking fresh engagement, we tailor our approach to each client’s unique goals and audience, delivering campaigns that stand out.

                        Our expertise spans the most powerful advertising platforms, including Google Ads and Facebook Ads, ensuring that your message reaches the right audience at the right time. We combine data-driven insights with creative vision to craft ads that resonate, optimize continuously to maximize performance, and provide transparent reporting so you can see the impact of every campaign.

                        ImpulseAd was founded on the belief that digital advertising should be accessible, effective, and above all, authentic. With our commitment to innovation and growth, we’re excited to be your partner on this journey. Let’s create something extraordinary together.
                    </p>
                    <img src={us} alt="us" />
                </div>
            </section>

            <section id="ourfeatures">
                <h2>Our Features</h2>
                <div className="maina">
                    <div className="feature">
                        <h3>Data-Driven Precision</h3>
                        <img src={dataP} alt="Data-Driven Precision" />
                        <p>Our campaigns are rooted in real-time analytics...</p>
                    </div>

                    <div className="feature">
                        <h3>Creative Strategy Development</h3>
                        <img src={creative} alt="Creative Strategy Development" />
                        <p>We craft ad strategies tailored to your brand’s personality...</p>
                    </div>

                    <div className="feature">
                        <h3>Transparent Performance Tracking</h3>
                        <img src={trace} alt="Transparent Performance Tracking" />
                        <p>Complete transparency with clear reporting...</p>
                    </div>

                    <div className="feature">
                        <h3>Adaptability & Rapid Optimization</h3>
                        <img src={optimize} alt="Adaptability & Rapid Optimization" />
                        <p>We monitor ad performance continuously...</p>
                    </div>

                    <div className="feature">
                        <h3>Personalized Client Support</h3>
                        <img src={clientp} alt="Personalized Client Support" />
                        <p>Our team is dedicated to personalized, responsive support...</p>
                    </div>

                    <div className="feature">
                        <h3>Cross-Platform Expertise</h3>
                        <img src={cross} alt="Cross-Platform Expertise" />
                        <p>We specialize in campaigns across Google Ads...</p>
                    </div>

                    <div className="feature">
                        <h3>Innovation-Focused Approach</h3>
                        <img src={innovation} alt="Innovation-Focused Approach" />
                        <p>We bring the latest tools and strategies...</p>
                    </div>

                    <div className="feature">
                        <h3>Flexible, Scalable Solutions</h3>
                        <img src={flexible} alt="Flexible, Scalable Solutions" />
                        <p>From startups to established businesses...</p>
                    </div>
                </div>
            </section>

            <section id="pricing">
                <h2>Our Pricing</h2>
                <div className="flex">
                    <div className="hovers">
                        <h3>Starter</h3>
                        <img src={starter} alt="Starter Plan" />
                        <ul>
                            <li>Ad campaign setup (Google & Social Media)</li>
                            <li>Basic keyword research & targeting</li>
                            <li>1 Ad design (image format)</li>
                            <li>Performance tracking & report</li>
                            <li>Email support</li>
                        </ul>
                        <h4>$49.99</h4>
                    </div>
                    <div>
                        <h3>Growth</h3>
                        <img src={growth} alt="Growth Plan" />
                        <ul>
                            <li>Advanced keyword & audience research</li>
                            <li>Multi-platform ad strategy (Google, Facebook, Instagram)</li>
                            <li>3 Ad creatives (image + video format)</li>
                            <li>A/B testing for better performance</li>
                            <li>Monthly strategy consultation</li>
                        </ul>
                        <h4>$129.99</h4>
                    </div>
                    <div>
                        <h3>Premium</h3>
                        <img src={premium} alt="Premium Plan" />
                        <ul>
                            <li>AI-driven ad optimization</li>
                            <li>Retargeting & conversion tracking</li>
                            <li>Custom landing page design</li>
                            <li>24/7 priority support</li>
                            <li>Dedicated account manager</li>
                        </ul>
                        <h4>$299.99</h4>
                    </div>
                </div>
            </section>

            <section id="contactus">
                <h2>Contact Us</h2>
                <div className="mainaa">
                    <form ref={form} onSubmit={send}>
                        <label>Your Name</label>
                        <input placeholder="Name" type="text" name="name" required />
                        <label>Your Email</label>
                        <input placeholder="Email" type="email" name="email" required />
                        <label>How Can We Help You?</label>
                        <textarea placeholder="Message" name="message" rows={10} required />
                        <button type="submit">Send</button>
                    </form>

                    <div className="social">
                        <a href="https://www.impulsead.com/">
                            <img src={fb} alt="fb" /> Facebook
                        </a>
                        <a href="https://www.impulsead.com/">
                            <img src={x} alt="x" /> X
                        </a>
                        <a href="https://www.impulsead.com/">
                            <img src={ig} alt="ig" /> Instagram
                        </a>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Mainpage
