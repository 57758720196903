import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer>
            <ul>
                <li><Link to="/#Aboutus">About Us</Link></li>
                <li><Link to="/#ourfeatures">Our Features</Link></li>
                <li><Link to="/#pricing">Our pricing</Link></li>
                <li><Link to="/#contactus">Contact Us</Link></li>
                <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                <li><Link to="/terms_and_condition">Terms And Conditions</Link></li>
            </ul>
        </footer>
    );
}

export default Footer;
