import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import './App.css';

import Mainpage from './pages/mainpage';
import Privacy from './pages/privacy';
import Terms from './pages/terms';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Mainpage />,
  },
  {
    path: '/privacy_policy',
    element: <Privacy />,
  },
  {
    path: '/terms_and_condition',
    element: <Terms />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} /> 
    </div>
  );
}

export default App;
