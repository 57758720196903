import Footer from './footer';
import Navbar from './navbar';

import React, { useEffect } from 'react';
import {  useLocation } from 'react-router-dom';

function ScrollToSection() {
    const location = useLocation();
  
    useEffect(() => {
      if (location.pathname === '/privacy_policy' || location.pathname === '/terms_and_condition') {
        window.scrollTo(0, 0);
      }
  
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  
    return null;
  }

function Terms() {
    return (<>
        <Navbar />
        <ScrollToSection /> 
        <section className="tad">
            <div>
                <h1>Terms and Conditions</h1>
                <p>Welcome to ImpulseAd! These terms and conditions outline the rules and regulations for the use of our website and services.</p>

                <h3>1. Acceptance of Terms</h3>
                <p>By accessing this website, you agree to be bound by these terms. If you do not agree, please do not use our website.</p>

                <h3>2. Services</h3>
                <p>ImpulseAd provides digital marketing and advertising solutions. All services are subject to availability and modification.</p>

                <h3>3. User Responsibilities</h3>
                <ul>
                    <li>Users must provide accurate information.</li>
                    <li>Users shall not misuse the website for illegal activities.</li>
                </ul>

                <h3>4. Pricing & Customization</h3>
                <p>The prices displayed on our website are <strong>for reference only</strong> and serve as an <strong>initial guideline</strong>.</p>
                <p>Final pricing may vary based on specific campaign requirements and business needs.</p>
                <p>Before proceeding with any service, <strong>users must contact us</strong> to discuss their needs and receive a custom quote.</p>
                <p>ImpulseAd reserves the right to <strong>accept or decline campaigns</strong> at our discretion.</p>

                <h3>5. Payments</h3>
                <p>All payments are processed securely. Prices are subject to change without prior notice.</p>

                <h3>6. Termination</h3>
                <p>ImpulseAd reserves the right to suspend or terminate access for violations of these terms.</p>

                <h3>7. Governing Law</h3>
                <p>These terms are governed by applicable laws.</p>
            </div>
        </section>
        <Footer /></>
    );
}

export default Terms