import Footer from './footer';
import Navbar from './navbar';

import React, { useEffect } from 'react';
import {  useLocation } from 'react-router-dom';

function ScrollToSection() {
    const location = useLocation();
  
    useEffect(() => {
      if (location.pathname === '/privacy_policy' || location.pathname === '/terms_and_condition') {
        window.scrollTo(0, 0);
      }
  
      if (location.hash) {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location]);
  
    return null;
  }


function Privacy() {
    return (<>      <Navbar />
     <ScrollToSection /> 
        <section className="tap">
            <div>
                <h1>Privacy Policy</h1>
                <h3>1. Information Collection</h3>
                <p>We collect personal information such as name, email, and payment details.</p>
                <p>Data is collected through forms, cookies, and tracking technologies.</p>

                <h3>2. Usage of Information</h3>
                <ul>
                    <li>To provide and improve our services.</li>
                    <li>To send promotional and service-related communications.</li>
                </ul>

                <h3>3. Data Protection</h3>
                <p>We use encryption and secure servers to protect your data. Data will not be shared with third parties without consent.</p>

                <h3>4. Your Rights</h3>
                <p>Users can request data access, modification, or deletion.</p>

                <h3>5. Pricing Disclaimer</h3>
                <p>The pricing displayed on our website is for general informational purposes only.</p>
                <p>Final costs will be determined <strong>after a consultation</strong> and evaluation of the specific campaign.</p>
                <p>Users must <strong>contact us first</strong> before we proceed with any service.</p>

                <p>For any inquiries, please contact us at <a href="mailto:lpramord@gmail.com">lpramord@gmail.com</a>.</p>
            </div>
        </section>
        <Footer /></>
    );
}

export default Privacy