import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './css/navbar.css'; // Ensure to create a CSS file for styles

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 700);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 700);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={`navbar ${isMobile ? 'mobile' : ''}`}>
            <div className="logo-container">
                <Link to="/" className="logo-link">
                    <img src="../img/logo.png" alt="Logo" className="logo" />
                    <span className="highlighted">ImpulseAd</span>
                </Link>
            </div>
            <button className="hamburger-menu" onClick={toggleMenu}>
                {isMenuOpen ? '✖' : '☰'}
            </button>
            <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                <li><Link to="#home">Home</Link></li>
                <li><Link to="#Aboutus">About Us</Link></li>
                <li><Link to="#ourfeatures">Our Features</Link></li>
                <li><Link to="#pricing">Our Pricing</Link></li>
                <li><Link to="#contactus">Contact Us</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
